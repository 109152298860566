import { default as indexTRqRpIOxLQMeta } from "/app/pages/about/index.vue?macro=true";
import { default as _91id_93VgxTOQ308lMeta } from "/app/pages/admin/food/[id].vue?macro=true";
import { default as createBHpLrtpkYCMeta } from "/app/pages/admin/food/create.vue?macro=true";
import { default as _91id_93YCas2QcFFjMeta } from "/app/pages/admin/food/edit/[id].vue?macro=true";
import { default as index5ZULpFJ7tpMeta } from "/app/pages/admin/food/index.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as _91id_93MfvuUJAnoLMeta } from "/app/pages/admin/plant/[id].vue?macro=true";
import { default as create3uUTpJ7qtjMeta } from "/app/pages/admin/plant/create.vue?macro=true";
import { default as _91id_938Gum0codh6Meta } from "/app/pages/admin/plant/edit/[id].vue?macro=true";
import { default as indexrUkAAK1qs2Meta } from "/app/pages/admin/plant/index.vue?macro=true";
import { default as indexFWiZLtrXXQMeta } from "/app/pages/admin/profile/index.vue?macro=true";
import { default as indexeAE1rz5sTVMeta } from "/app/pages/admin/search/index.vue?macro=true";
import { default as _91id_93AhycT2htcuMeta } from "/app/pages/admin/travel/[id].vue?macro=true";
import { default as create66RZhKx1oWMeta } from "/app/pages/admin/travel/create.vue?macro=true";
import { default as _91id_939uIaTrjlLqMeta } from "/app/pages/admin/travel/edit/[id].vue?macro=true";
import { default as indexyRpqetbVRjMeta } from "/app/pages/admin/travel/index.vue?macro=true";
import { default as create28zg1vRv7cMeta } from "/app/pages/admin/user/create.vue?macro=true";
import { default as _91id_938Z77RhLRKsMeta } from "/app/pages/admin/user/edit/[id].vue?macro=true";
import { default as indexOhQ6NGykTuMeta } from "/app/pages/admin/user/index.vue?macro=true";
import { default as _91id_93YWW3j3IVhVMeta } from "/app/pages/admin/wisdom/[id].vue?macro=true";
import { default as createOpPKgFeUK3Meta } from "/app/pages/admin/wisdom/create.vue?macro=true";
import { default as _91id_93Zh8DsFwZq6Meta } from "/app/pages/admin/wisdom/edit/[id].vue?macro=true";
import { default as indexzjwbKJIsQPMeta } from "/app/pages/admin/wisdom/index.vue?macro=true";
import { default as forgetPasswordD1rpIQLxvDMeta } from "/app/pages/auth/forgetPassword.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as _91_46_46_46params_938l9fCKl2ijMeta } from "/app/pages/auth/reset-password/[...params].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: indexTRqRpIOxLQMeta || {},
    component: () => import("/app/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-food-id",
    path: "/admin/food/:id()",
    meta: _91id_93VgxTOQ308lMeta || {},
    component: () => import("/app/pages/admin/food/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-food-create",
    path: "/admin/food/create",
    meta: createBHpLrtpkYCMeta || {},
    component: () => import("/app/pages/admin/food/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-food-edit-id",
    path: "/admin/food/edit/:id()",
    meta: _91id_93YCas2QcFFjMeta || {},
    component: () => import("/app/pages/admin/food/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-food",
    path: "/admin/food",
    meta: index5ZULpFJ7tpMeta || {},
    component: () => import("/app/pages/admin/food/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-plant-id",
    path: "/admin/plant/:id()",
    meta: _91id_93MfvuUJAnoLMeta || {},
    component: () => import("/app/pages/admin/plant/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-plant-create",
    path: "/admin/plant/create",
    meta: create3uUTpJ7qtjMeta || {},
    component: () => import("/app/pages/admin/plant/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-plant-edit-id",
    path: "/admin/plant/edit/:id()",
    meta: _91id_938Gum0codh6Meta || {},
    component: () => import("/app/pages/admin/plant/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-plant",
    path: "/admin/plant",
    meta: indexrUkAAK1qs2Meta || {},
    component: () => import("/app/pages/admin/plant/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-profile",
    path: "/admin/profile",
    meta: indexFWiZLtrXXQMeta || {},
    component: () => import("/app/pages/admin/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-search",
    path: "/admin/search",
    meta: indexeAE1rz5sTVMeta || {},
    component: () => import("/app/pages/admin/search/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-travel-id",
    path: "/admin/travel/:id()",
    meta: _91id_93AhycT2htcuMeta || {},
    component: () => import("/app/pages/admin/travel/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-travel-create",
    path: "/admin/travel/create",
    meta: create66RZhKx1oWMeta || {},
    component: () => import("/app/pages/admin/travel/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-travel-edit-id",
    path: "/admin/travel/edit/:id()",
    meta: _91id_939uIaTrjlLqMeta || {},
    component: () => import("/app/pages/admin/travel/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-travel",
    path: "/admin/travel",
    meta: indexyRpqetbVRjMeta || {},
    component: () => import("/app/pages/admin/travel/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-user-create",
    path: "/admin/user/create",
    meta: create28zg1vRv7cMeta || {},
    component: () => import("/app/pages/admin/user/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-user-edit-id",
    path: "/admin/user/edit/:id()",
    meta: _91id_938Z77RhLRKsMeta || {},
    component: () => import("/app/pages/admin/user/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-user",
    path: "/admin/user",
    meta: indexOhQ6NGykTuMeta || {},
    component: () => import("/app/pages/admin/user/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-wisdom-id",
    path: "/admin/wisdom/:id()",
    meta: _91id_93YWW3j3IVhVMeta || {},
    component: () => import("/app/pages/admin/wisdom/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-wisdom-create",
    path: "/admin/wisdom/create",
    meta: createOpPKgFeUK3Meta || {},
    component: () => import("/app/pages/admin/wisdom/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-wisdom-edit-id",
    path: "/admin/wisdom/edit/:id()",
    meta: _91id_93Zh8DsFwZq6Meta || {},
    component: () => import("/app/pages/admin/wisdom/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-wisdom",
    path: "/admin/wisdom",
    meta: indexzjwbKJIsQPMeta || {},
    component: () => import("/app/pages/admin/wisdom/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgetPassword",
    path: "/auth/forgetPassword",
    meta: forgetPasswordD1rpIQLxvDMeta || {},
    component: () => import("/app/pages/auth/forgetPassword.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password-params",
    path: "/auth/reset-password/:params(.*)*",
    meta: _91_46_46_46params_938l9fCKl2ijMeta || {},
    component: () => import("/app/pages/auth/reset-password/[...params].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]